<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
<!--          <v-col cols>-->
<!--            <h3>-->
<!--              User ສູນຄັດແຍກ-->
<!--              &lt;!&ndash;              <span class="primary-color">({{ factories.length }})</span>&ndash;&gt;-->
<!--            </h3>-->
<!--          </v-col>-->
          <v-col cols>
            <v-select
                required
                outlined
                dense
                v-model="factorySelected"
                :items="listFactories"
                item-text="name"
                item-value="id"
            >
            </v-select>
          </v-col>
          <v-col cols>
            <v-select
                required
                outlined
                dense
                v-model="SortingCenterSelected"
                :items="sorting_centers"
                item-text="name"
                item-value="id"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>  <v-col cols="6">
          <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍ ຊື່, Email"
              type="text"
              v-model="search"
          >
          </v-text-field>
        </v-col>
                    <v-spacer></v-spacer>
          <v-col cols="2" class="d-flex justify-end">
            <template>
              <div>
                <v-btn
                    class="btn-primary"
                    fab
                    small
                    dark
                    @click="OpenModalAdd()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </template>
          </v-col>

        </v-row>

        <v-data-table
            :headers="headers"
            :items="resultSearch"
            :items-per-page="15"
            class="elevation-1"
            :loading="TableLoading"
            :disabled="TableLoading"
            loading-text="Loading... Please wait"
        >
          <!--Action -->
          <template v-slot:item.actions="{ item }">
<!--            <v-icon small class="mr-2" @click="OpenchangePassword(item.userId)">-->
<!--              mdi-key-->
<!--            </v-icon>-->
            <v-icon small class="mr-2" @click="OpenModalEdit(item)">
              mdi-pencil
            </v-icon>
                        <v-icon small @click="deleteItem(item.ware_house_userId)">
                          mdi-delete
                        </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ເພີ່ມ User ສາງໂຮງງານ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name *"
                        required
                        v-model="sorting_center.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email *"
                        required
                        v-model="sorting_center.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="selectedRole"
                        :items="roles"
                        item-text="display_name"
                        item-value="id"
                        label="User Role *"
                        multiple
                        :rules="ruleRole"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        label="ໂຮງງານ *"
                        v-model="factorySelecteAdd"
                        :items="listFactories"
                        item-text="name"
                        item-value="id"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        label="ສາງໂຮງງານ *"
                        v-model="sortingCenterSelecteAdd"
                        :items="sorting_centers"
                        item-text="name"
                        item-value="id"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Password*"
                        type="password"
                        v-model="sorting_center.password"
                        :rules="passwordRules"
                        required
                        @keyup.enter="AddFactory"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddFactory()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close" v-slot="">
        <v-card>
          <v-card-title>
            <span>Update user ສາງໂຮງງານ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name*"
                        required
                        v-model="sorting_center_edit.user_name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email*"
                        required
                        v-model="sorting_center_edit.user_email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        v-model="sorting_center_edit.roleUser"
                        :items="roles"
                        item-text="display_name"
                        item-value="id"
                        label="User Role *"
                        multiple
                    >
                    </v-select>
                    <p class="errors">
                      {{ server_errors.roleId }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="UpdateSortingCenterUser()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>

    <!--Change Password -->
    <v-dialog v-model="dialogChangePassword" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Password*"
                      type="password"
                      v-model="new_password"
                      :rules="passwordRules"
                      required
                      @keyup.enter="ChangePassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogChangePassword = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="ChangePassword"
          >
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import manage_factory from "../../../mixins/sortingCenter/manage_user_sorting_center";

export default {
  mixins: [manage_factory],
  metaInfo() {
    return {
      title: `ຂໍ້ມູນ Sorting Center`,
      meta: [
        {name: 'description', content: 'Sorting Center'},
      ]
    }
  },
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>