export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            dialogChangePassword: false,
            roles: [],
            listFactories: [],
            factorySelected: "",
            factorySelecteAdd:"",
            SortingCenterSelected: "",
            sortingCenterSelecteAdd: "",

            filtterRole: [],
            sorting_centers: [],
            sorting_center: {},
            selectedRole: '',
            selectedEditRole: '',
            sortingCenterID: '',
            server_errors: {
                email: '',
                roleId: ''
            },
            rolesID: {},
            userId: "",
            sorting_center_edit: {},
            sorting_center_users: [],
            new_password: '',
            search: '',
            headers: [
                {
                    text: "User ID",
                    align: "start",
                    sortable: false,
                    value: "user_card_id",
                },
                {
                    text: "User name",
                    align: "start",
                    value: "user_name",
                },
                {text: "Email", value: "user_email"},
                {text: "Role", value: "roleUserName"},
                {text: "Actions", value: "actions", sortable: false},
            ],
            // ruleRole:[(v) => !!v || "User Role is required",],
            ruleRole: [(v) => !!v || 'User Role is required',],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            passwordRules: [
                (v) => !!v || "Password is required",
                (v) =>
                    (v && v.length >= 6) || "Password must be more than 6 characters",
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length >= 2) || 'Name must be less than 2 characters',
            ],
            toast: {
                value: true,
                color: 'success',
                msg: 'Success'
            },
            toast_error: {
                value: true,
                color: 'error',
                msg: 'Something when wrong!'
            }

        }
    },
    methods: {
        OpenModalAdd(id) {
            this.sortingCenterID = id;
            this.$store.commit("modalAdd_State", true);
            this.getRole();
        },
        AddFactory() {
            if (this.$refs.form.validate() == true) {
                this.SubmitFactory();
            }
        },
        SubmitFactory() {
            this.sorting_center.roles = this.selectedRole;
            this.loading = true;
            if (this.sortingCenterSelecteAdd) {
                this.$axios.post('factory/add-warehouse-user/' + this.sortingCenterSelecteAdd, this.sorting_center)
                    .then(res => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.CloseModalAdd();
                                this.sorting_center = {};
                                this.getListUserSortingCenter();
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    }).catch(error => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.getListUserSortingCenter();
                    // this.reset();
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, factory] of Object.entries(obj)) {
                            this.server_errors[key] = factory[0];
                        }
                    }
                });
            }
        },
        CloseModalAdd() {
            this.factory = {};
            this.reset();
            this.getListUserSortingCenter();
            this.$store.commit("modalAdd_State", false);
        },
        OpenModalEdit(item) {
            this.sorting_center_edit = item;
            this.getRole();
            this.$store.commit("modalEdit_State", true);
        },

        UpdateSortingCenterUser() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$axios.put('factory/edit-warehouse-user/' + this.sorting_center_edit.ware_house_userId, {
                    name: this.sorting_center_edit.user_name,
                    email: this.sorting_center_edit.user_email,
                    roles: this.sorting_center_edit.roleUser
                })
                    .then(res => {
                        if (res.data.success == true) {
                            this.loading = false;
                            this.CloseModalEdit();
                            this.sorting_center_edit = {};
                            this.reset();
                            this.getListUserSortingCenter();
                            this.$store.commit("Toast_State", this.toast);
                        }
                    }).catch(error => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.getListUserSortingCenter();
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, factory] of Object.entries(obj)) {
                            this.server_errors[key] = factory[0];
                        }
                    }
                });
            }
        },

        CloseModalEdit() {
            this.reset();
            this.sorting_center_edit = {};
            this.getListUserSortingCenter();
            this.$store.commit("modalEdit_State", false);
        },

        async getListUserSortingCenter() {
            this.TableLoading = true;
            await this.$axios.get('factory/list-warehouse-users/' + this.SortingCenterSelected).then(res => {
                // setTimeout(() => {
                this.sorting_center_users = res.data.listWareHouseUsers;
                console.log(this.sorting_center_users)
                this.TableLoading = false;
                // }, 100);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        async getRole() {
            await this.$admin.get('list-role').then(res => {
                // this.roles = res.data.roles;
                let rolesUser = [
                    "factorywarehouseuser"
                ];
                this.roles = res.data.roles.filter(item => rolesUser.includes(item.name));

            }).catch(() => {
                this.$store.commit("Toast_State", this.toast_error);
            });
        },

        async getListSortingCenter() {
            await this.$axios.get('factory/list-warehouses/'+ this.factorySelected).then(res => {
                this.sorting_centers = res.data.data;
                this.SortingCenterSelected = res.data.data[0].id;
            }).catch(() => {
            });
        },
        getListFactory() {
            this.$admin.get('list-factories').then(res => {
                this.listFactories = res.data.data.data;
                this.factorySelected = res.data.data.data[0].id;
            }).catch(() => {
            });
        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },

        deleteItem(userId) {
            this.userId = userId;
            this.$store.commit("modalDelete_State", true);
        },

        async deleteItemConfirm() {
            this.loading = true;
            await this.$axios.delete('factory/delete-warehouse-user/' + this.userId).then(res => {
                console.log(res);
                if (res.status == 200) {
                    this.getListUserSortingCenter();
                    this.$store.commit("modalDelete_State", false);
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast);
                }
            }).catch(() => {
                this.$store.commit("Toast_State", this.toast_error);
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        },

        OpenchangePassword(userId) {
            this.new_password = '',
                this.userId = userId;
            this.dialogChangePassword = true;
        },

        ChangePassword() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin.post('change-password', {
                    password: this.new_password,
                    userId: this.userId,
                }).then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.dialogChangePassword = false;
                            this.new_password = '',
                                this.loading = false;
                            this.getListUserSortingCenter();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 300)
                    }
                }).catch(() => {
                    this.loading = false;
                    this.getListUserSortingCenter();
                    this.$store.commit("Toast_State", this.toast_error);
                })
            }
        },
        reset() {
            this.$refs.form.reset();
        },

    },
    computed: {
        resultSearch() {
            if (this.search) {
                return this.sorting_center_users.filter((item) => {
                    let filtered = true;
                    if (this.search.length > 0) {
                        filtered = item.user_name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) || item.user_email
                            .toLowerCase()
                            .includes(this.search.toLowerCase());
                    }
                    return filtered;
                });
            } else {
                return this.sorting_center_users;
            }
        },

    },

    watch: {
        'sorting_center.email': function () {
            this.server_errors.email = '';
        },
        factorySelected: function () {
            this.getListSortingCenter();
        },
        factorySelecteAdd: function () {
            this.getListSortingCenter();
        },

        'SortingCenterSelected': function () {
            this.getListUserSortingCenter();
        },
        'sorting_center_edit.user_email': function () {
            this.server_errors.email = '';
        },
    },
    created() {
        this.getListFactory();
        if(this.factorySelected){
            this.getListSortingCenter();
        }
        if (this.SortingCenterSelected) {
            this.getListUserSortingCenter();
        }
    },
}